<template>
  <navbar></navbar>
  <div>
    <div>
      <div class="sp-area">
        <div v-if="!autionDetails">
          <NotFound></NotFound>
        </div>

        <div class="container" v-else>
          <vue-easy-lightbox :visible="visible" :imgs="path + imgs123" @hide="handleHide">
          </vue-easy-lightbox>

          <div class="sp-nav">
            <div class="row">
              <div class="col-lg-6" v-if="vehImageArry">
                <div class="sp-img_area">

                  <Carousel v-if="vehImageArry.length !== 0" id="gallery" :items-to-show="1" :wrap-around="false"
                    v-model="currentSlide">

                    <Slide v-for="(slide, index) in vehImageArry" :key="index">
                      <div class="carousel__item">
                        <img :src="path + slide.imageurl" />
                      </div>
                    </Slide>
                    <template #addons>
                      <Navigation />
                    </template>
                  </Carousel>

                  <Carousel v-if="vehImageArry.length !== 0" id="thumbnails" :items-to-show="4" :wrap-around="true"
                    v-model="currentSlide" ref="carousel" :navigation-enabled="true">
                    <Slide v-for="(slide, index) in vehImageArry" :key="index">
                      <div class="carousel__item">
                        <img :src="path + slide.imageurl" @click="slideTo(index)" />
                      </div>
                    </Slide>
                    <template #addons>
                      <Navigation />
                    </template>
                  </Carousel>
                </div>
              </div>

              <div class="col-lg-6" v-if="autionDetails">
                <div class="sp-content">
                  <div class="sp-heading">
                    <h5>
                      <a style="color: #312d92" href="#">{{ autionDetails.brandName }}
                        {{ autionDetails.modelName }}
                        {{ autionDetails.manYear }}</a>
                    </h5>
                  </div>
                  <div v-if="autionDetails.vehdescription != null">
                    <p>{{ autionDetails.vehdescription }}.</p>
                  </div>

                  <div class="row">
                    <div class="col-md-12">
                      <div class="flex-j-c-sb">
                        <h6>Details</h6>
                        <span>#{{autionDetails.vin}}</span>
                      </div>
                      <div class="tablerounededCorner">
                        <table class="table table-striped roundedTable">
                          <!-- <tr>
                            <th>Heading 1</th>
                            <th>Heading 2</th>
                          </tr> -->
                          <!-- <tr>
                            <td>Body Type</td>
                            <td>N/A</td>
                          </tr> -->
                          <tr>
                            <td>Brand Name</td>
                            <td>{{ autionDetails.brandName }}</td>
                          </tr>
                          <tr>
                            <td>Model Name</td>
                            <td>{{ autionDetails.modelName }}</td>
                          </tr>
                          <!-- <tr>
                            <td>Vehicle Registration</td>
                            <td>
                              {{ autionDetails.vehRegNo }}
                            </td>
                          </tr> -->
                          <!-- <tr v-if="userinfo">
                            <td>Vehicle Registration</td>
                            <td>{{ autionDetails.vehRegNo }}</td>
                          </tr> -->
                          <!-- <tr>
                            <td>Motor No.</td>
                            <td>N/A</td>
                          </tr>
                          <tr>
                            <td>Exterior colour</td>
                            <td>N/A</td>
                          </tr> -->
                          <tr>
                            <td>Km Clocked</td>
                            <td>{{ autionDetails.kmClocked }} KM</td>
                          </tr>
                          <tr>
                            <td>Year Of Make</td>
                            <td>{{ autionDetails.manYear }}</td>
                          </tr>
                          <!-- <tr>
                            <td>Fuel</td>
                            <td>N/A</td>
                          </tr> -->
                        </table>
                      </div>
                    </div>
                    <!-- <div class="col-md-6">
                      <div class="btnBack">
                           <button class="btn btn-primary" @click="goBack()">Go Back</button>
                      </div>
                    
                    </div> -->
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div style="display: none">
            <h4 class="auction-log-head">Auction Log</h4>
            <div class="bod">
              <table class="table">
                <thead>
                  <tr>
                    <!-- <th>S.No</th> -->
                    <th>Auction Name</th>
                    <th>Vin No</th>
                    <th>Customer Name</th>
                    <th>Latest Bid Amount</th>
                    <th>
                      <Button class="btn btn-danger refresh-last-d"
                        @click="getAuctionLogByAucId(autionDetails.aucId)">Refresh</Button>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(bid, index) in bidLog" :key="index">
                    <!-- <td data-label="S.No"></td> -->
                    <td data-label="Name">{{ bid.auction }}</td>
                    <td data-label="Vin">{{ bid.vin }}</td>
                    <td data-label="Customer Name">{{ bid.custName }}</td>
                    <td data-label="Amount">
                      <p>{{ $n(bid.bidAmount, "currency", language) }}</p>
                    </td>
                    <td data-label=""></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <!-- Auction Status Alert Model -->
        <div class="overlay-bid-btn" v-if="openShowModel && userinfo">
          <button class="close-bid-btn bid-popup-close" @click="(openShowModel = false), (message = '')">
            x
          </button>
          <h4>! Warning</h4>
          <p>
            Are you sure you want to add
            <span>{{ auctionEdit.brandName }} {{ auctionEdit.modelName }}
            </span>
            with bid amount :
            {{ $n(auctionEdit.latestBidAmount, "currency", language) }} to
            {{ modelnameAuc }}? ! this action is irreversible
          </p>
          <div class="row">
            <div class="col-6">
              <div>
                <button class="bid-info-sub" @click="openShowModel = false">
                  No
                </button>
              </div>
            </div>
            <div class="col-6">
              <div>
                <button class="bid-info-sub" @click="changeAucStatus(auctionEdit, modelnameAuc)">
                  Yes
                </button>
              </div>
            </div>
          </div>
          <div class="form-group" v-if="auctionStatusMessage == 'Success'">
            <div class="alert alert-warning" role="alert">
              {{ auctionStatusMessage }}
            </div>
          </div>
          <div class="form-group" v-else-if="auctionStatusMessage">
            <div class="alert alert-warning" role="alert">
              {{ auctionStatusMessage }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  // import  local from '../../locales/en.json'
  import NotFound from "@/components/modules/NotFound.vue";
  //import CountDown from "@/components/modules/CountDown.vue";
  import "vue3-carousel/dist/carousel.css";
  import { Carousel, Slide, Navigation } from "vue3-carousel";
  import userService from "@/services/user.service";
  import Config from "@/config.js";
  import navbar from "@/components/navbar.vue";
  export default {
    name: "VehicleDetails",
    components: {
      Carousel,
      Slide,
      //CountDown,
      // Pagination,
      Navigation,
      NotFound,
      navbar,
    },
    // props:{
    //     VehicleDetails:Array
    // },
    data() {
      return {
        visible: false,
        index: 0, // default: 0
        path: Config.BASE_URL_IMG,
        imgs123: "",
        autionDetails: [],
        timer: "",
        imageArray: [],
        openModel: false,
        amount: "",
        AuctionsDetails: "",
        userinfo: "",
        custId: "",
        bidAmount: "",
        Buttondisabled: false,
        selectedAuction: "",
        message: "",
        bidLog: [],
        modelnameAuc: "",
        auctionEdit: "",
        openShowModel: false,
        language: Config.BASE_LANG,
      };
    },
    created() {
      this.userinfo = JSON.parse(localStorage.getItem("user"));
      if (this.userinfo) {
        if (typeof this.userinfo.sellerId != "undefined") {
          let sellerId = this.userinfo.sellerId;
          let urlkey = this.$route.params.url_key;
          if (!sellerId || !urlkey) {
            this.$router.go(-1);
          } else {
            this.getSellerAuctionsListbysellerIdVechId(sellerId, urlkey);
          }
        }
      }
    },
    methods: {
      goBack() {
        history.back();
      },
      showImg(imgs123) {
        this.imgs123 = imgs123;
        this.visible = true;
      },
      handleHide() {
        this.visible = false;
      },
      loadimage(img) {
        this.imgs123 = img;
      },
      ShowAlert(e, r) {
        this.modelnameAuc = r;
        this.auctionEdit = e;
        this.openShowModel = !this.openShowModel;
      },
      changeAucStatus(aucInfo, aucStatus) {
        this.updAuctionStatus(aucInfo, aucStatus);
      },
      updAuctionStatus(auctionDetails, aucStatus) {
        //console.log(auctionDetails, aucStatus);

        userService
          .updAuctionStatus(auctionDetails, aucStatus)
          .then((response) => {
            this.message = response.data.Message;
            if (this.message == "Success") {
              var self = this;
              setTimeout(function () {
                self.hideModal();
              }, 2000);
            }
          })
          .catch((e) => {
            console.log(e);
          });
      },

      hideModal() {
        this.openModel = false;
        this.message = "";
        location.reload();
      },
      getSellerAuctionsListbysellerIdVechId(sellerId, vehid) {
        userService
          .getSellerAuctionsListbysellerIdVechId(vehid, sellerId)
          .then((response) => {
            this.autionDetails = response.data.Data[0];
            this.imgs123 = this.autionDetails.vehImage1;
            this.timer = this.autionDetails.aucExtDate;
            let image1 = null;
            let image2 = null;
            let image3 = null;
            let image4 = null;
            let image5 = null;
            let image6 = null;
            let image7 = null;
            let image8 = null;
            let image9 = null;
            let image10 = null;
            let image11 = null;
            let image12 = null;
            let image13 = null;
            let image14 = null;
            let image15 = null;

            if (typeof this.autionDetails.vehImage1 != "undefined") {
              image1 = this.autionDetails.vehImage1;
            } //else{image1  = ""};
            if (typeof this.autionDetails.vehImage2 != "undefined") {
              image2 = this.autionDetails.vehImage2;
            } //else{image2  = ""};
            if (typeof this.autionDetails.vehImage3 != "undefined") {
              image3 = this.autionDetails.vehImage3;
            } //else{image3  = ""};
            if (typeof this.autionDetails.vehImage4 != "undefined") {
              image4 = this.autionDetails.vehImage4;
            } //else{image4  = ""};
            if (typeof this.autionDetails.vehImage5 != "undefined") {
              image5 = this.autionDetails.vehImage5;
            } //else{image5  = ""};
            if (typeof this.autionDetails.vehImage6 != "undefined") {
              image6 = this.autionDetails.vehImage6;
            } //else{image6  = ""};
            if (typeof this.autionDetails.vehImage7 != "undefined") {
              image7 = this.autionDetails.vehImage7;
            } //else{image7  = ""};
            if (typeof this.autionDetails.vehImage8 != "undefined") {
              image8 = this.autionDetails.vehImage8;
            } //else{image8  = ""};
            if (typeof this.autionDetails.vehImage9 != "undefined") {
              image9 = this.autionDetails.vehImage9;
            } //else{image9  = ""};
            if (typeof this.autionDetails.vehImage10 != "undefined") {
              image10 = this.autionDetails.vehImage10;
            } //else{image10 = ""};
            if (typeof this.autionDetails.vehImage11 != "undefined") {
              image11 = this.autionDetails.vehImage11;
            } //else{image11 = ""};
            if (typeof this.autionDetails.vehImage12 != "undefined") {
              image12 = this.autionDetails.vehImage12;
            } //else{image12 = ""};
            if (typeof this.autionDetails.vehImage13 != "undefined") {
              image13 = this.autionDetails.vehImage13;
            } //else{image13 = ""};
            if (typeof this.autionDetails.vehImage14 != "undefined") {
              image14 = this.autionDetails.vehImage14;
            } //else{image14 = ""};
            if (typeof this.autionDetails.vehImage15 != "undefined") {
              image15 = this.autionDetails.vehImage15;
            } //else{image15 = ""};

            // console.log(this.auctionifos);
            let imageArry = [
              { imageurl: image1 },
              { imageurl: image2 },
              { imageurl: image3 },
              { imageurl: image4 },
              { imageurl: image5 },
              { imageurl: image6 },
              { imageurl: image7 },
              { imageurl: image8 },
              { imageurl: image9 },
              { imageurl: image10 },
              { imageurl: image11 },
              { imageurl: image12 },
              { imageurl: image13 },
              { imageurl: image14 },
              { imageurl: image15 },
            ];

            this.vehImageArry = imageArry.filter(function (el) {
              return el.imageurl != null;
            });

            // this.getauctionlogbyid(this.autionDetails.aucId);
            this.getAuctionLogByAucId(this.autionDetails.aucId);
          })
          .catch((e) => {
            console.log(e);
            // this.$router.push("/VechDetails").catch(() => {
            //   "details/auctionbyname";
            // });
          });
      },
      getAuctionLogByAucId(aucId) {
        userService.getAuctionLogByAucId(aucId).then((response) => {
          this.bidLog = response.data.Data;
        });
      },
    },
  };
</script>
<style>
  .detailsBtn:hover {
    background-color: #4caf50;
    /* Green */
    color: white;
  }

  .countdownlefttop {
    /* background-color: white; */
    display: flex;
    padding: 5px;
    height: 35px;
    border-radius: 3px;
    float: right;
    background-color: transparent;
  }

  .digit {
    margin: 0px;
  }

  /* .countdownlefttop {
  padding: 0px;
  height: 0px;
} */
  .btn-park {
    background-color: #eaecf0;

    color: rgb(2, 2, 2);
    border: 3px solid rgb(61, 124, 206);
    padding: 0px 17px;
    border-radius: 4px;
    margin-left: 10px;
    margin-right: 10px;
  }

  .btn-lost {
    background-color: #e4e4f0;
    color: rgb(2, 2, 2);
    border: 3px solid rgb(233, 52, 29);
    padding: 0px 17px;
    border-radius: 4px;

    /* margin-left: 10px; */
    /* margin-right: 10px; */
  }

  .bod {
    margin: 0;
    padding: 20px;
    font-family: lato;
  }

  * {
    box-sizing: border-box;
  }

  .table {
    width: 100%;
    border-collapse: collapse;
  }

  td th {
    padding: 12px 15px;
    border: 1px solid #ddd;
    text-align: center;
    font-size: 16px;
  }

  th {
    background-color: #648ed1;
    color: #ffffff;
  }

  tbody {
    background-color: #f5f5f5;
  }

  /* background-color: #e8e9ee;
    border: 2px solid #e8e9ee; */

  /*responsive*/

  @media (max-width: 500px) {
    .table thead {
      display: none;
    }

    .table,
    .table tbody,
    .table tr,
    .table td {
      display: block;
      width: 100%;
    }

    .table tr {
      margin-bottom: 15px;
    }

    .table td {
      text-align: right;
      padding-left: 50%;
      text-align: right;
      position: relative;
    }

    .table td::before {
      content: attr(data-label);
      position: absolute;
      left: 0;
      width: 50%;
      padding-left: 15px;
      font-size: 15px;
      font-weight: bold;
      text-align: left;
    }
  }
</style>